<template>
  <div>
    <a
      class="text-primary d-flex align-items-center mb-1"
      @click="$emit('goToLogIn')"
    >
      <feather-icon
        icon="ChevronLeftIcon"
        size="22"
        class="cursor-pointer text-primary"
      />
      Back to Sign In
    </a>
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Two Factor Authentication
    </b-card-title>
    <b-card-text class="mb-2">
      Your OTP was sent to <span class="text-primary">{{ otpContact }}</span> and
      it will expire in {{ otpExpiry }}.
    </b-card-text>
    <validation-observer ref="otp-validation-observer">
      <b-form @submit.prevent="verifyOtp">
        <b-form-group
          label="Enter Your Code"
          label-for="otp-input"
        >
          <validation-provider
            #default="{ errors }"
            name="OTP"
            rules="required|max:6|numeric"
          >
            <b-form-input
              id="otp-input"
              v-model="otp"
              placeholder="······"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          block
          type="submit"
          variant="primary"
          class="mt-1"
          :disabled="isSubmitting"
        >
          <b-spinner
            v-if="isSubmitting"
            small
          />
          Submit
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max, numeric } from '@validations'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'OTPScreen',
  components: { ValidationProvider, ValidationObserver },
  mixins: [helper],
  props: {
    userEmail: {
      type: String,
      default: '',
    },
    password: {
      type: String,
      default: '',
    },
    otpInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      otp: null,
      isSubmitting: false,
      required,
      max,
      numeric,
    }
  },
  computed: {
    otpContact() {
      return this.otpInfo.email || this.otpInfo.phone
    },
    otpExpiry() {
      return moment.duration(this.otpInfo.otp_expiry, 'seconds').humanize()
    },
  },
  methods: {
    verifyOtp() {
      this.$refs['otp-validation-observer'].validate().then(success => {
        if (success) {
          this.isSubmitting = true
          const formData = {
            username: this.userEmail.toLowerCase(),
            password: this.password,
            otp: this.otp,
          }
          api.postData('auth/otp_login/', false, formData)
            .then(response => {
              if (response.data.status) {
                if (response.data.result.user.account_info.is_b2e_customer) {
                  this.$emit('setUserData', response.data.result)
                } else {
                  this.notificationMessage('danger', 'XIcon', 'Error',
                    'Seems like you\'re not a Corporate User.')
                }
              }
            }).catch(err => {
              const errorMessage = err.response && err.response.data.non_field_errors
                ? err.response.data.non_field_errors[0] : ''
              if (errorMessage) {
                this.notificationMessage('danger', 'XIcon', 'Error', errorMessage)
              } else {
                this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
              }
            }).finally(() => {
              this.isSubmitting = false
            })
        }
      })
    },
  },
}
</script>
