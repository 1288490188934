<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <GoZayaanBrandLogo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <OTPScreen
            v-if="isOtpEnabled"
            :user-email="userEmail"
            :password="password"
            :otp-info="otpInfo"
            @setUserData="setUserData"
            @goToLogIn="isOtpEnabled=false"
          />
          <template v-else>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Welcome to GoZayaan Corporate! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Please sign-in to your account and start the adventure
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="signIn"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{name:'forgot-password'}">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <p
                  v-if="message"
                  class="text-danger"
                >
                  {{ message }}
                </p>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isLoading"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>
          </template>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import GoZayaanBrandLogo from '@/components/icons/GoZayaanBrandLogo.vue'
import store from '@/store/index'
import api from '@/libs/api'
import account from '@/mixins/account'
import OTPScreen from '@/views/auth/OTPScreen.vue'

export default {
  components: {
    GoZayaanBrandLogo,
    ValidationProvider,
    ValidationObserver,
    OTPScreen,
  },
  mixins: [togglePasswordVisibility, account],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      isOtpEnabled: false,
      otpInfo: {},
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
      message: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    signIn() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.message = ''
          api.postData('auth/v2.0/login/', false, {
            username: (this.userEmail).toLowerCase(),
            password: this.password,
            login_type: 'AD',
          }).then(response => {
            if (response.data.status) {
              if (response.data.result.OTP) {
                this.isOtpEnabled = true
                this.otpInfo = response.data.result.OTP
              } else {
                const isB2ECustomer = response.data.result.Result.user
                  && response.data.result.Result.user.account_info.is_b2e_customer
                if (isB2ECustomer) {
                  this.setUserData(response.data.result.Result)
                } else {
                  this.message = 'Seems like you\'re not a Corporate User.'
                }
              }
            }
          }).catch(error => {
            if (error.response && error.response.status === 400) {
              this.message = 'Email or password is incorrect.'
            } else {
              this.message = 'Something went wrong, please try again later.'
            }
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
